.radioInfoMenu {
	background-color: $darkBlue;
	border-radius: 0.5rem;

	padding: 1rem;

	display: flex;
	flex-direction: column;

	position: fixed;
	top: 2rem;
	left: 0.4rem;

	z-index: 5;
}

.radioInfoMenuItem {
	background: none;
	outline: none;
	border: none;

	color: $white;
	font-size: 1.75rem;
	font-family: "Quicksand", sans-serif;
	font-weight: 300;

	margin: 0.25rem;

	cursor: pointer;

	&:hover {
		opacity: 80%;
	}
}
