.radioInfoAlbum {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.radioInfoAlbumLinkCont {
	width: 100%;

	display: flex;
	justify-content: center;
}

.radioInfoAlbumLink {
	text-decoration: none;
}

.radioInfoAlbumPic {
	max-width: 19rem;
	max-height: 19rem;

	border-radius: 0.5rem;

	margin-right: 0.5rem;
}

.radioInfoAlbumInfoContainer {
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
}

.radioInfoAlbumTitle {
	font-size: 2rem;
	margin-bottom: 0.5rem;
	font-weight: 300;
	text-align: center;
}

.radioInfoAlbumInfoBttnContainer {
	display: flex;
	justify-content: center;
	width: 100%;
}

.radioInfoAlbumBttn {
	font-size: 1.35rem;
	background-color: $darkBlue;
	text-decoration: none;
	border-radius: 0.5rem;
	padding: 0.45rem;
	display: inline-block;

	&:first-child {
		margin-right: 1rem;
	}
}
