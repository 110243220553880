@mixin mainBackground {
	width: 100%;
	min-height: $main-height;

	background-image: linear-gradient(rgba($black, 0.6), rgba($black, 0.6)),
		url(../../images/organHands.webp);
	background-size: cover;
	background-position: top;
}

@mixin mainGrayBackground {
	width: 100%;
	min-height: $main-height;

	background-color: $offBlack;
	position: relative;
}

@mixin centerElm {
	display: grid;
	justify-items: center;
}

@mixin iPhoneNotchFix {
	padding: env(safe-area-inset-top) env(safe-area-inset-right)
		env(safe-area-inset-bottom) env(safe-area-inset-left);
}

@mixin pointer {
	cursor: pointer;
}

@mixin font {
	font-family: "Quicksand", sans-serif;
	font-weight: 300;
}