#header {
	position: sticky;
	top: 0;

	width: 100%;
	height: $navbar-height;

	margin: 0;
	padding: 0.5rem 5rem;
	z-index: 10;

	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: $darkBlue;
	color: $white;
	border-bottom: solid 1px $white;

	@media screen and (min-width: $tablet-max) {
		justify-content: space-evenly;
	}

	@media screen and (max-height: $mobile-max) and (orientation: portrait) {
		justify-content: space-between;
		padding: 1rem 1rem;
	}
}

#header-content {
	position: relative;

	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

#title-logo {
	max-width: 9rem;
	max-height: 100%;

	user-select: none;

	@media screen and (max-height: $mobile-max), (max-width: $mobile-max) {
		max-height: 6.5rem;
	}

	@media screen and (max-height: $mobile-max) and (orientation: portrait) {
		margin-top: 1rem;
		max-width: 9rem;
		max-height: 6rem;
		margin: 0rem;
	}

	@media screen and (max-width: $mobile-max) and (orientation: landscape) {
		max-width: 9rem;
		max-height: 6rem;
		margin: 0rem;
	}
}

#title-link {
	margin: 0;
	padding: 0;
	height: 100%;
	&:hover {
		color: $darkGray;
	}

	h1 {
		height: 100%;
	}
}

.radio-link {
	outline: none;
	background: none;
	border: none;

	font-size: 2.4rem;
	color: $white;

	margin: 0 0.8rem;
	padding: 0.8rem;

	@include font;
}

.dropdown-arrow {
	position: relative;
}

// Dropdown Menu

#dropdown-container {
	position: absolute;
	top: 5.9em;
	right: 0;
	height: 20rem;
	width: 28rem;

	overflow: hidden;
}

#dropdown {
	position: absolute;

	width: 24rem;
	height: 17rem;
	margin: 0;

	top: 0.5rem;
	right: 1.6rem;

	border-radius: 0.5rem;
	box-shadow: 0 0 1rem $black;
}

.dropdown-animation {
	&-enter {
		position: absolute;
		transform: translateY(-28rem);
		transition: transform 0.5s ease;
	}

	&-enter-active {
		transform: translateY(0);
		transition: transform 0.5s ease;
	}

	&-exit {
		transition: transform 0.5s ease;
	}

	&-exit-active {
		transform: translateY(-28rem);
		transition: transform 0.5s ease;
	}
}

.dropdown-list {
	position: absolute;
	overflow: hidden;

	width: 100%;
	height: 100%;
	padding: 0.3rem;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-evenly;

	color: $black;
	background-color: $darkBlue;

	border-radius: 1rem;
}

.dropdown-links:visited,
.dropdown-links:link {
	display: block;
	text-decoration: none;
	color: $white;

	font-size: 2.5rem;
	font-weight: 300;
	text-align: right;

	width: 100%;
	padding: 0.4rem;
	border-radius: 0.4rem;

	&:hover {
		color: $gray;
	}
}

.dropdown-item {
	list-style-type: none;
	align-items: center;
	margin: 0.25rem;
	padding: 0.1rem;
	width: 100%;
	text-align: right;
}

// Mobile Menu Style ---------------------------------------------------------------

#header-content-mobile {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#mobile-menu {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100vw;
	background-color: $black;

	overflow: auto;

	@include iPhoneNotchFix;

	&::-webkit-scrollbar {
		display: none;
	}

	display: flex;
	justify-content: flex-start;
	align-items: flex-end;
	flex-direction: column;

	@media (orientation: landscape) {
		overflow-y: auto;
	}
}

.mobile-menu-animation {
	&-enter {
		opacity: 0;
		transform: translateX(100vw);
		transition: transform 0.5s ease;
	}

	&-enter-active {
		opacity: 1;
		transform: translateX(0);
		transition: transform 0.5s ease;
	}

	&-exit {
		transform: translateX(0);
		transition: transform 0.5s ease;
	}

	&-exit-active {
		transform: translateX(100vw);
		transition: transform 0.5s ease;
	}
}

#mobile-menu-list {
	width: 100vw;
	padding-right: 1rem;

	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: flex-end;
	text-align: right;

	@media screen and (max-width: $mobile-max-landscape-width) and (orientation: landscape) {
		font-size: 3rem;
	}
}

.mobile-dropdown-item {
	position: relative;
	text-decoration: none;
	color: white;
	font-size: 4rem;

	margin: 0.5rem 0;
}

#hamburger-icon {
	outline: none;
	border: none;
	background: none;

	font-size: 3.5rem;
	color: white;

	user-select: none;

	margin: 0;
	padding: 0;

	@media screen and (max-width: $tablet-max) and (min-width: $mobile-max-width-portrait) {
		font-size: 4rem;
	}
}

#x-toggle {
	font-size: 4.5rem;
}
