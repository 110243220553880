#library-list {
	max-width: 85%;
	margin: 1rem;
	position: relative;
	padding: 0 2rem;
	padding-bottom: 3rem;

	display: grid;
	place-items: center;

	grid-template-columns: repeat(auto-fit, minmax(31rem, 1fr));
	column-gap: 4rem;
	row-gap: 4rem;

	@media screen and (max-width: $tablet-max) {
		max-width: 100%;
		grid-column-gap: 2.4rem;
		grid-row-gap: 2.4rem;
	}

	// Firefox fix that makes sure the width isn't just one item wide
	@-moz-document url-prefix() {
		min-width: 85%;
	}
}

.list-link {
	text-decoration: none;

	height: 17.5rem;
	width: 32rem;

	margin: 2rem;

	@media screen and (max-width: 33rem) {
		width: 30rem;
	}
}

// list item container

.list-container {
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	color: $white;
	height: 100%;
	width: 100%;
	max-width: 90vw;
	background-color: $darkGray;

	box-shadow: -0.2rem 0 0 0 $white;
	transition: all 0.1s;

	&:hover {
		opacity: 0.8;
	}
}
