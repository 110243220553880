.music-info {
	display: flex;
	flex-direction: column;
	justify-content: center;

	* {
		text-align: center;
	}
}

.music-info-title {
	margin-bottom: 0.5rem;
}

.music-info-title-p {
	font-size: 2.5rem;
	display: inline-block;
	font-weight: 300;
}

.musicInfoArtistLink {
	font-size: 2rem;
	text-decoration: none;
	display: inline-block;

	&:hover {
		opacity: 80%;
	}
}

.radioInfoWorkBuyingOptions {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 0.5rem;
}

.radioInfoWorkBuyingOptionsItemLink {
	font-size: 1.35rem;
	background-color: $darkBlue;

	text-decoration: none;

	border-radius: 0.5rem;

	padding: 0.45rem;

	user-select: none;

	&:not(:first-child) {
		margin-left: 0.5rem;
	}

	&:hover {
		opacity: 80%;
	}
}
