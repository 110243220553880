.play-progress-buttons-cont {
	display: flex;
	align-items: center;
	justify-content: center;
	
	& * {
		color: $white;
	}
}

.play-progress-buttons-cont-mobile {
	position: absolute;
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);
}

.play-progress-arrow-cont {
	display: flex;
	align-items: center;
}

.play-progress-arrow-text {
	font-size: 1.5rem;
}

.play-progress-arrows {
	outline: none;
	background: none;
	border: none;
}

#playback-button {
	background: none;
	border: none;
	color: $white;
	font-size: 4.5rem;

	&:hover {
		cursor: pointer;
	}
}

.playButton {
	width: 5rem;

	outline: none;
	border: none;
}

#progress-container {
	display: flex;
	align-items: center;

	width: 95%;

	position: absolute;
	bottom: 6rem;
	right: 50%;
	transform: translateX(50%);
}


#progress-background {
	background-color: $transparent-gray;
	width: 100%;
	height: 0.55rem;
	border-radius: 2rem;

	margin: 0 0.35rem;
}

#progress {
	height: 100%;
	background: $white;
	border-radius: 2rem;
}

.song-nums {
	font-size: 1.45rem;
}
