#schedule {
	display: flex;
	align-items: flex-start;
	justify-content: center;

	color: $white;
	font-size: 2rem;

	min-height: $main-height;
	width: 100%;
	padding: 5rem;

	background-image: linear-gradient(rgba($black, 0.5), rgba($black, 0.5)),
		url(../../images/organHands.webp);
	background-size: cover;
	background-position: top;

	@media screen and (max-width: $tablet-max) and (orientation: portrait) {
		padding: 1.75rem;
	}

	@media screen and (max-width: $tablet-max) and (orientation: landscape) {
		padding: 2rem;
	}
}

#schedule-inner {
	position: relative;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background-color: $darkBlue;
	box-shadow: 0.3rem 0.3rem $off-white;
	border-radius: 1.4rem;

	width: 45%;
	margin: 1rem;

	@media screen and (max-width: $tablet-max) and (orientation: portrait) {
		width: 90%;
		margin: 0.1rem;
	}

	@media screen and (max-width: $tablet-max) and (orientation: landscape) {
		width: 95%;
		right: 0.4rem;
	}
}

#schedule-title {
	display: block;
}

.day {
	font-size: 2.6rem;
	display: inline-block;
}

.schedule-item {
	margin: 1rem 0;
	padding: 1rem;
	width: 95%;

	border-bottom: 1px solid $white;
}

.schedule-item-text {
	margin-top: 1rem;
}

#schedule-APOBA {
	padding: 2.5rem;
}
