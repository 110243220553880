#radio {
	width: 100vw;
	height: 100vh;
	background-color: $darkGray;
}

#radioInner {
	display: flex;
	align-items: center;

	height: 100%;
	width: 100%;

	position: relative;
}

#radio-x {
	position: absolute;
	top: 0.2rem;
	right: 0.4rem;

	font-size: 2.35rem;

	cursor: pointer;
	user-select: none;
}

//Mobile Small Radio

#radio-small-mobile {
	max-width: 6;
	max-height: 5;
	margin: 0 1.2rem;
}

#radio-small-icon {
	outline: none;
	border: none;
	background: none;

	color: $white;
	font-size: 2.5rem;
	@include font;

	margin-right: 1rem;

	cursor: pointer;
	user-select: none;

}

// Radio listener count
#radioListeners {
	font-size: 1.55rem;
	position: absolute;
	bottom: 8.5rem;
	right: 50%;
	transform: translateX(50%);
}

.play-progres-cont {
	display: flex;
	align-items: center;
}

// Play Button and Skip buttons/ Volume Controls Container

.radioPlayVolumeCont {
	position: absolute;
	bottom: 1rem;
	left: 50%;
	transform: translateX(-50%);

	display: flex;
	justify-content: center;
	align-items: center;

	& > div:first-child {
		margin-right: 8rem;
	}
}
