// Album item text

.library-album-text {
	max-width: 50%;
	text-align: left;
	font-weight: 500;
	font-size: 1.6rem;
}

// font-size if there is no picture for item

.no-pic-text {
	max-width: 100%;
	font-size: 3rem;
}

.pics {
	width: 12rem;
	height: 12rem;
	border: 0.8px solid rgba(0, 0, 0, 0.74);
	border-radius: 50%;
	object-fit: cover;
}

// Album Item Individual Page

#detailedAlbum {
	@include mainGrayBackground;
	padding: 2rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media screen and (max-width: $mobile-max) {
		padding: 1rem;
	}
}

#detailedAlbumInner {
	width: 97%;
	max-height: 95%;
	padding: 3rem 2rem;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	background-color: $darkGray;
	box-shadow: -0.2rem 0 0 0 $white;

	& > * {
		margin: 0.5rem 0;
	}

	@media screen and (min-width: $tablet-max) {
		width: 55%;
	}

	@media screen and (max-width: $tablet-max) {
		width: 75%;
	}

	@media screen and (max-width: $tablet-width) {
		width: 90%;
	}

	@media screen and (max-width: $mobile-max) {
		width: 97%;
	}
}

#detailedAlbumInfo {
	width: 100%;
}

#detailedAlbumTitleRating {
	width: 100%;
}

#detailedALbumTitle {
	font-size: 2rem;
	margin-bottom: 0.2rem;
}

#detailedAlbumOrganist {
	font-size: 1.5rem;
	margin-bottom: 0.3rem;

	display: flex;
	flex-wrap: wrap;

	@media screen and (max-width: $mobile-max) {
		flex-direction: column;
	}
}

.detailedAlbumBy {
	margin-right: 0.2rem;
}

.detailedAlbumByArray {
	margin: 0.2rem 0.2rem;
}

.detailedAlbumOrganistLinkContainer {
	margin: 0.2rem 0.2rem;
}

.detailedAlbumOrganistLink {
	text-decoration: none;
	max-height: 1.6rem;

	color: $white;

	transition: all 0.2s ease;

	&:hover {
		filter: brightness(0.8);
	}
}

#detailedAlbumBuyingOptions {
	width: 100%;
	margin: 1rem 0;
}

.albumBuyingOptions {
	height: 100%;
	font-size: 1.6rem;
	padding: 0.5rem;
	margin: 0.5rem;
	color: $white;

	text-decoration: none;
	border-radius: 0.1rem;
	background-color: $black;
	border-radius: 0.3rem;

	transition: all 0.2s ease;

	&:hover {
		filter: brightness(0.75);
	}
}

.detailedAlbumImage {
	border-radius: 1rem;
	margin-top: 0.5rem;

	max-width: 70%;
	max-height: 70%;

	@media (max-width: $mobile-max) {
		width: 95%;
	}
}

.albumImageNotLoaded {
	opacity: 0;
}

.detailedALbumSongList {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;

	width: 100%;
}

.detailedAlbumSongListTitleContainer {
	width: 100%;
	margin: 1rem 0 0 1rem;

	text-decoration: underline;
}

.detailedAlbumSongListItem {
	width: 54rem;
	height: 25rem;
	margin: 1rem;
	padding: 1rem;

	background-color: $offBlack;

	position: relative;

	@media screen and (max-height: $mobile-max) {
		min-height: 20rem;
		margin: 1rem 0.5rem;

		width: 38rem;
	}

	@media screen and (max-height: $tablet-max) {
		min-height: 20rem;
		margin: 1rem 0.5rem;

		width: 36rem;
	}
}

.detailedAlbumSongListTitle {
	font-size: 2.2rem;
	margin-bottom: 0.4rem;

	@media screen and (max-height: $mobile-max) {
		font-size: 2rem;
	}

	@media screen and (max-width: 28rem) {
		font-size: 1.8rem;
	}
}

.detailedAlbumSongListComposer {
	font-size: 2.1rem;

	@media screen and (max-height: $mobile-max) {
		font-size: 1.75rem;
	}

	@media screen and (max-width: 28rem) {
		font-size: 1.5rem;
	}
}

.detailedALbumSongListDuration {
	font-size: 2rem;

	@media screen and (max-height: $mobile-max) {
		font-size: 1.5rem;
	}
}

.detailedAlbumSongListBuyingOptions {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	row-gap: 1.5rem;

	position: absolute;
	bottom: 0.75rem;
	left: 0.25rem;
}

.detailedAlbumSongListContainer {
	margin: 0 0.5rem;

	@media screen and (max-width: 28rem) {
		margin-top: 1.5rem;
	}
}

.detailedAlbumSongListSheetMusicBttn {
	background: none;
	border: none;
	outline: none;
	text-decoration: none;

	background-color: $bttnColor;
	color: $white;
	font-size: 1.5rem;

	padding: 1rem;
	border-radius: 0.2rem;

	@media screen and (max-width: $mobile-max-width-portrait) {
		font-size: 1.25rem;
	}

	@media screen and (max-width: 28rem) {
		padding: 0.5rem;
	}
}

// Request Button
.detailedAlbumSongListRequestBttn {
	background: none;
	border: none;
	outline: none;
	text-decoration: none;

	background-color: $bttnColor;
	color: $white;
	font-size: 1.5rem;

	padding: 1rem;
	border-radius: 0.2rem;

	@media screen and (max-width: $mobile-max-width-portrait) {
		font-size: 1.25rem;
	}

	@media screen and (max-width: 28rem) {
		padding: 0.5rem;
	}
}

// Album list for detailed composers and organists pages

.detailedAlbumList {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;

	width: 100%;
}

.detailedAlbumListContainer {
	background-color: $offBlack;
	display: flex;

	width: 36rem;
	max-width: 97%;

	margin: 1rem;
	padding: 1.2rem;
}

.detailedAlbumListLink {
	display: flex;
	text-decoration: none;
	color: $white;

	transition: all 0.3s ease;

	&:hover {
		filter: brightness(0.75);
	}
}

.detailedAlbumListImage {
	height: 15rem;
	width: 15rem;

	@media screen and (max-width: 364px) {
		max-width: 50%;
	}

	transition: all 0.2s ease;

	// &:hover {
	// 	filter: brightness(0.8);
	// }
}

.detailedAlbumListInfo {
	margin: 0 1rem;
	padding: 0 0.2rem;

	height: 100%;
	max-width: 50%;
}

.detailedAlbumListName {
	font-size: 1.8rem;

	@media screen and (max-width: 330px) {
		font-size: 1.5rem;
	}
}
