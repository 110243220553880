*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	font-family: "Quicksand", sans-serif;
	text-rendering: auto;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
}

::selection {
	background-color: grey;
}

body {
	font-weight: 300;
	background-color: $black;
	color: white;

	overflow-y: hidden;

	@media screen and (max-height: $mobile-max) and (max-width: $mobile-max) {
		@include iPhoneNotchFix;
	}
}
