.skeleton {
	opacity: 0.7;
	animation: skeleton-loading 1s linear infinite alternate;
}

.list-content-container {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
	flex-direction: column;

	height: 45%;
	min-width: 45%;
}

.skeleton-text {
	min-width: 100%;
	height: 1.2rem;
	text-align: start;

	&:nth-last-child(2) {
		min-width: 90%;
	}

	&:last-child {
		min-width: 80%;
	}
}

// Detailed Library Item

.detailedSkeleton {
	@include mainGrayBackground;
	padding: 2rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.detailedInnerSkeleton {
	width: 97%;
	max-height: 95%;
	padding: 3rem 2rem;

	background-color: $darkGray;
	box-shadow: -0.2rem 0 0 0 $white;

	& > * {
		margin: 0.5rem 0;
	}

	@media screen and (min-width: $tablet-max) {
		width: 55%;
	}

	@media screen and (max-width: $tablet-max) {
		width: 75%;
	}

	@media screen and (max-width: $tablet-width) {
		width: 90%;
	}

	@media screen and (max-width: $mobile-max) {
		width: 97%;
	}
}

.detailedTitleSkeleton {
	height: 2rem;
	width: 40%;
	margin-bottom: 0.2rem;
}

.detailedInfoSkeleton {
	height: 1.5rem;
	width: 30%;
	margin-bottom: 0.3rem;
}

.detailedOtherOptionsSkeleton {
	width: 15%;
	height: 2rem;
}

.detailedImageSkeleton {
	height: 60rem;
	border-radius: 1rem;

	max-width: 98%;
}

@keyframes skeleton-loading {
	0% {
		background-color: hsl(200, 5%, 70%);
	}

	100% {
		background-color: hsl(200, 5%, 95%);
	}
}
