#footer {
	// position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 1.4rem;

	background-color: $darkBlue;

	height: 10rem;
	width: 100%;

	@media screen and (max-height: $mobile-max) {
		padding: 1rem;
	}
}

#footer-text {
	text-align: center;
}

.organ-media-fotter-link {
	color: white;
	opacity: .8;
}