#submit-recordings {
	@include mainBackground;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

#submit-recordings-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	padding: 5rem 2rem;

	@media (min-width: $tablet-max) {
		width: 45%;

		background-color: $darkBlue;
		border-radius: 1rem;
		box-shadow: 0.3rem 0.3rem $white;
	}
}

#submit-recordings-info {
	font-size: 2rem;
	margin-bottom: 1.4rem;
}

#submit-recordings-addrs {
	font-size: 2.8rem;
}
