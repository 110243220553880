// Colors
$babyBlue: #c3e0e5;
$darkBlue: #1d3557;
$blueGray: #5885af;
$midnightBlue: #41729f;
$off-white: #e4e4e4;
$white: #fff;
$black: #000;
$gray: gray;
$darkGray: #1f1f1f;
$playerBackground: #0f0f0f;
$offBlack: #171717;
$bttnColor: #222222;

// Transparent Colors
$transparent-blueGray: rgba($blueGray, 0.4);
$transparent-darkBlue: rgba($darkBlue, 0.8);
$transparent-midnightBlue: rgba($midnightBlue, 0.5);
$transparent-black: rgba($black, 0.5);
$transparent-playerBackground: rgba($playerBackground, 0.7);
$transparent-gray: rgba($gray, 0.5);

// Navbar Height
$navbar-height: 7rem;

// Breakpoints
$tablet-max: 1500px;
$tablet-width: 1200px;

$mobile-max: 1000px;
$mobile-max-width-portrait: 490px;
$mobile-max-height: 850px;
$mobile-max-landscape-width: 830px;

// Main Content Height
$main-height: calc(100vh - #{$navbar-height});
