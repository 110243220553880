#contact {
	@include mainGrayBackground;

	@media screen and (min-height: $tablet-max) {
		padding-top: 15rem;
		padding-bottom: 3rem;
	}

	padding-top: 2rem;
	padding-bottom: 2rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;
}

#contact-inner {
	@media screen and (min-width: $tablet-max) {
		width: 40%;
	}

	width: 95%;
	padding: 5rem;

	display: flex;
	justify-content: center;
	flex-direction: column;

	background-color: $darkBlue;
	border-radius: 1rem;
	box-shadow: 0.3rem 0.3rem $white;
}

// Contact Text

#contact-text {
	@media screen and (min-width: $tablet-max) {
		text-align: justify;
	}
}

#hear-from-you {
	font-size: 3.3rem;
	margin: 2rem 0;
}

#contact-title {
	font-size: 5rem;
	margin: 2rem 0;
}

.contact-text-content {
	font-size: 2rem;
	margin: 2rem 0;
}

// Contact Form

#contact-form {
	position: relative;

	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}

.contact-form-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	overflow: hidden;

	position: relative;

	@media screen and (min-width: $tablet-max) {
		width: 70%;
		margin: 0.5rem;
	}

	width: 100%;
	height: 10rem;
}

.contact-label {
	font-size: 2.6rem;
	display: block;

	pointer-events: none;

	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-bottom: 0.1rem solid $white;

	&::after {
		content: "";

		position: absolute;
		left: 0rem;
		bottom: -0.1rem;

		height: 100%;
		width: 100%;

		transform: translateX(-100%);
		transition: transform 0.3s ease;

		border-bottom: 0.1rem solid $gray;
	}
}

.label-content {
	position: absolute;
	left: 0;
	bottom: 0.5rem;
	transition: all 0.3s ease;
	font-family: "Quicksand", sans-serif;
}

.contact-form-input {
	background: transparent;
	outline: none;
	border: none;
	width: 100%;
	height: 100%;
	padding-top: 5rem;
	color: $white;

	font-family: "Quicksand", sans-serif;
	font-size: 2rem;
	font-weight: 300;

	&:focus + .contact-label .label-content,
	&:valid + .contact-label .label-content {
		transform: translateY(-150%);
		color: $gray;
		font-size: 2rem;
	}

	&:focus + .contact-label::after,
	&:valid + .contact-label::after {
		transform: translateX(0%);
	}

	transition: all 0.2s ease;
}

// Main message section

#contact-message-container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;

	position: relative;

	margin: 9rem 0.5rem 0.5rem 0;
	width: 100%;

	@media screen and (min-width: $tablet-max) {
		width: 70%;
		margin: 9rem 0.5rem 0.5rem 0.5rem;
	}
}

#contact-textarea {
	width: 100%;
	height: 15rem;
	margin-top: 1rem;
	padding: 1rem;

	@media screen and (min-width: $tablet-max) {
		width: 100%;
	}

	position: relative;

	resize: none;
	overflow: hidden;
	background: none;
	outline: none;
	border: 0.1rem solid $white;
	border-radius: 0.5rem;

	font-family: "Quicksand", sans-serif;
	font-size: 1.8rem;
	font-weight: 300;
	color: $white;

	&:focus + #contact-message-label #contact-message-content,
	&:valid + #contact-message-label #contact-message-content {
		color: $gray;
		font-size: 2rem;
	}

	&:focus,
	&:valid {
		border: 0.1rem solid $gray;
	}
}

#contact-message-label {
	position: absolute;
	top: 0;
	font-size: 2.6rem;

	transition: all 0.3s ease;

	transform: translateY(-100%);

	display: flex;
	flex-direction: column;
}

#contact-message-content {
	transition: all 0.3s ease;
}

.contact-input-error {
	color: red;
	font-size: 2rem;
	transition: all 0.5s ease-in-out;
}

// Submit Button

#contact-submit-bttn {
	outline: none;
	cursor: pointer;

	border: 0.1rem solid $white;
	border-radius: 0.5rem;
	background: none;
	color: $white;

	width: 13rem;
	height: 5rem;

	font-size: 2rem;

	transition: all 0.3s ease;

	margin-top: 9rem;

	@media screen and (min-width: $tablet-max) {
		margin: 0.5rem;
		margin-top: 5rem;
		padding: 1rem;
	}

	&:hover {
		color: $gray;
		border: 0.1rem solid $gray;
	}
}

// Socials

#contact-socials {
	margin-top: 5rem;
	width: 45%;

	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: $mobile-max) {
		width: 100%;
	}
}

.social-icon {
	max-width: 5rem;
	max-height: 5rem;

	// pointer-events: none;
}
