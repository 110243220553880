.radioInfoOrgan {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.radioInfoOrganInstitution {
	font-size: 3rem;
	font-weight: 300;
	text-align: center;
}

.radioInfoOrganLink {
	text-decoration: none;
	text-align: center;
}

.radioInfoOrganDateAndBuilder {
	font-size: 2rem;
}

.radioInfoOrganLocation {
	font-size: 2rem;
}
