.radioInfoArtist {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.radioInfoArtistLinkCont {
	display: flex;
	justify-content: center;
	align-items: center;
}

.radioInfoArtistPic {
	max-width: 20rem;
	max-height: 20rem;
	margin-right: 0.5rem;
	border-radius: 0.25rem;
}

.radioInfoArtistLink {
	text-decoration: none;
}

.radioInfoArtistName {
	font-size: 2.2rem;
	margin-bottom: 0.5rem;
}

.radioInfoArtistInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.radioInfoAritstBioBttn {
	font-size: 1.75rem;
	background-color: $darkBlue;
	text-decoration: none;
	border-radius: 0.25rem;
	padding: 0.45rem;
	display: inline-block;
}
