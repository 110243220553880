.organistListText {
	font-size: 2rem;
}

.detailedOrganist {
	@include mainGrayBackground;
	padding: 2rem 1rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media screen and (max-width: $mobile-max) {
		width: 100%;
	}
}

.detailedOrganistInner {
	max-width: 98%;
	max-height: 95%;
	padding: 3rem 2rem;

	display: flex;
	align-items: flex-start;
	flex-direction: column;

	background-color: $darkGray;
	box-shadow: -0.2rem 0 0 0 $white;

	& > * {
		margin: 0.5rem 0;
	}

	@media screen and (min-width: $tablet-max) {
		width: 75%;
	}

	@media screen and (max-width: $tablet-max) {
		width: 75%;
	}

	@media screen and (max-width: $mobile-max) {
		width: 98%;
		padding: 3rem 1rem;
	}

	@media screen and (max-width: 43rem) {
		padding: 3rem 0;
	}
}

.detailedOrganistName {
	font-size: 2.5rem;

	margin-left: 0.5rem;
}

.detailedOrganistLink {
	font-size: 1.8rem;
	color: $white;
	text-decoration: underline;

	margin-left: 0.5rem;
}

.detailedOrganistImage {
	border-radius: 0.5rem;

	margin-left: 0.5rem;

	@media screen and (min-width: 351px) {
		max-height: 15rem;
		max-width: 15rem;
	}

	@media screen and (max-width: 350px) {
		max-height: 15rem;
		max-width: 15rem;
	}
}

.detailedOrganistAlbumListDescription {
	font-size: 2rem;
	text-decoration: underline;

	margin-top: 2rem;
	margin-left: 0.5rem;
}

.detailedOrgnistAlbumList {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;

	width: 100%;
}

.detailedOrganistAlbumContainer {
	background-color: $offBlack;
	display: flex;

	width: 36rem;
	max-width: 97%;

	margin: 1rem;
	padding: 1.2rem;
}

.detailedOrganistAlbumLink {
	display: flex;
	text-decoration: none;
	color: $white;

	transition: all 0.3s ease;

	&:hover {
		filter: brightness(0.75);
	}
}

.detailedOrganistAlbumInfo {
	margin: 0 1rem;
	padding: 0 0.2rem;

	height: 100%;
	max-width: 50%;
}

.detaiedOrganistAlbumName {
	font-size: 1.8rem;

	@media screen and (max-width: 330px) {
		font-size: 1.5rem;
	}
}

.detailedOrganistAlbumImage {
	height: 15rem;
	width: 15rem;

	@media screen and (max-width: 364px) {
		max-width: 50%;
	}

	transition: all 0.2s ease;

	// &:hover {
	// 	filter: brightness(0.8);
	// }
}
