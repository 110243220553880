.radioInfo {
	position: absolute;
	bottom: 11rem;
	left: 50%;
	transform: translateX(-50%);

	width: 94%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	& * {
		color: $white;
	}
}

.expandMore {
	position: fixed;
	top: 0;
	left: 0;

	margin: 0;
	padding: 0;

	font-size: 2.75rem;

	cursor: pointer;
	user-select: none;
}

.radioInfoIntermission {
	font-size: 2.5rem;
	text-align: center;
}
