#home {
	@include mainBackground;

	@include centerElm;

	@media screen and (max-width: $mobile-max) {
		align-items: flex-start;
	}
}

#home-inner {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	padding: 1rem;

	width: 60%;

	& > * {
		margin: 1rem 0;
	}

	@media (min-width: $tablet-max) {
		& > * {
			margin: 2rem 0;
		}
	}

	@media screen and (max-width: $tablet-max) {
		width: 90%;
		align-items: flex-start;
		text-align: left;
		padding-top: 4rem;
	}

	@media screen and (max-width: $mobile-max) {
		width: 91%;
	}
}

#home-title {
	font-size: 12rem;
	color: $white;
	
	@media screen and (max-width: $mobile-max) and (orientation: portrait) {
		font-size: 5rem;
	}

	@media screen and (max-width: $mobile-max) and (orientation: landscape) {
		font-size: 6rem;
	}
}

#home-text {
	width: 80%;
	font-size: 3rem;
	color: $white;

	@media screen and (max-width: $tablet-width) {
		width: 100%;
		font-size: 2.5rem;
	}

	@media screen and (max-height: $mobile-max) {
		font-size: 2.1rem;
		width: 100%;
	}

	@media screen and (max-width: $mobile-max) {
		width: 100%;
		font-size: 2rem;
	}
}

.home-listen-now {
	outline: none;
	background: none;
	border: none;

	user-select: none;

	height: 7rem;
	width: 18rem;
	font-size: 2.4rem;
	font-weight: 300;
	border-radius: 0.5rem;

	background-color: $darkBlue;
	color: $white;

	&:hover,
	&:active {
		color: $darkGray;
	}
}
