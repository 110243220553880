.button {
	font-family: "Quicksand", sans-serif;
	font-weight: 300;
	background-color: black;
	border: none;
	color: white;
	outline: none;
	padding: 10px;
	margin: 0 0.8rem;
	padding: 0 0 0 10px;
	font-weight: 300;
}

#more-button {
	font-family: "Quicksand", sans-serif;
	font-weight: 300;
	background: none;
	border: none;
	color: white;
	outline: none;
}

#more-button:hover {
	cursor: pointer;
	transition: all 0.2s;
}
