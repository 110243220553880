.App {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	min-height: calc(100% + env(safe-area-inset-top));
	min-width: 100%;

	overflow-x: hidden;
}
