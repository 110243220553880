#search {
	margin: 2rem;
	width: 100vw;
	color: $white;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

#list-options-container {
	margin: 0 4rem;
	padding: 0.25rem;
	display: flex;
	flex-direction: row;
}

.list-option {
	margin: 0;
	display: inline-block;
	font-size: 2.2rem;
	padding: 0.5rem;
	height: 100%;

	&:hover {
		cursor: pointer;
		color: darkgrey;
		transition: all 0.25s;
	}
}

.list-option-underlinded {
	border-bottom: 0.1rem solid $white;
}

// Sort Options

.searchBttn {
	cursor: pointer;
	font-size: 3.5rem;
}

.selectArrowCont {
	display: flex;
}

.searchSelect {
	position: relative;

	border: none;
	outline: none;
	background: transparent;
	background-color: $offBlack;

	-webkit-appearance: none;
	box-shadow: none;

	border-radius: 1rem;
	padding: 1rem;
	width: 18rem;

	font-size: 1.8rem;
	color: $white;

	& + .selectArrowDown {
		transform: translateX(-100%) translateY(0.5rem);
	}
}

.selectArrowDown {
	padding: none;
	margin: none;
	pointer-events: none;
}

// Mobile --------------------------

#mobile-search {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	width: 100%;
	padding-top: 1rem;

	& > * {
		margin: 0.5rem;
	}
}

// Search Form --------------------------------

.search-form-cont {
	display: grid;
	place-items: center;

	padding: 1rem;
}

.search-form-inner {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	position: relative;

	background-color: $darkGray;
	box-shadow: -0.2rem 0 0 0 #fff;

	padding: 2rem 1rem;

	& > * {
		margin: 2rem 0;
	}

	@media screen and (max-width: $mobile-max) {
		max-width: 95vw;
	}

	@media screen and (min-width: $tablet-max) {
		// width: 40rem;
		// height: 45rem;
	}
}

// Search form

.search-form {
	display: flex;
	flex-direction: column;

	width: 55rem;
	max-width: 98%;

	& > * {
		margin: 1rem 0;
	}
}

.searchFormInputsCont {
	width: 100%;

	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 2fr));
	grid-gap: 1rem;
}

.searchBttnMessage {
	display: flex;
	align-items: center;
}

.searchError {
	font-size: 1.75rem;
	margin-left: 1rem;
	color: rgb(255, 34, 34);
}

// Title

.searchFormTitle {
	font-size: 2.5rem;
	margin: 0;
}

// Select Options

.selectSearchLabel {
	position: relative;

	font-size: 2rem;
	padding-left: 0.75rem;
	bottom: 0.5rem;
}

.selectArrowContWidth {
	width: 20rem;
}

.searchSelectWidth {
	width: 100%;
}

.libraryListSortContainer {
	position: absolute;
	top: 1rem;
	right: -1rem;
}

.libraryListSortInput {
	background-color: $darkGray;
}

// Text Input

.searchInputLabelContainer {
	overflow: visible;

	position: relative;

	width: 25rem;
}

#searchInput {
	outline: none;
	border: none;
	background: none;

	position: relative;

	font-family: "Quicksand", sans-serif;
	font-size: 1.85rem;
	font-weight: 300;
	color: $white;

	background-color: $offBlack;
	border-radius: 0.25rem;

	height: 3.5rem;
	width: 100%;
	padding: 0 0.5rem;
}

#searchInputLabel {
	font-size: 1.85rem;
	pointer-events: none;

	position: absolute;
	top: 13%;
	left: 0.5rem;
}

.searchButton {
	background: none;
	border: none;
	outline: none;

	border-radius: 0.5rem;
	padding: 0.55rem 1.5rem;
	cursor: pointer;

	font-family: "Quicksand", sans-serif;
	font-size: 1.75rem;
	color: $white;
	font-weight: 300;

	background-color: $offBlack;
}

// No results component

// Container
.no-results {
	@include mainGrayBackground;

	display: grid;
	place-items: center;
}

.noResultsText {
	font-size: 3.5rem;
}

// Search Results

// Container
.searchItem {
	height: 17.5rem;
	width: 32rem;

	position: relative;

	box-shadow: -0.2rem 0 0 0 $white;
	background-color: $darkGray;

	padding: 1rem;
}

.searchItemLink {
	text-decoration: none;
	color: $white;

	&:hover {
		color: $darkGray;
	}
}

.searchItemAlbumText {
	font-size: 1.6rem;
	font-weight: 300;
	color: $white;

	margin-bottom: 0.5rem;

	&:hover {
		opacity: 70%;
	}
}

.searchItemTitle {
	font-size: 1.85rem;
	margin-bottom: 0.5rem;
}

.searchItemRequestBttn {
	background: none;
	border: none;
	outline: none;

	font-size: 1.5rem;
	color: $white;
	font-weight: 300;
	border-radius: 0.5rem;
	background-color: $darkBlue;

	padding: 0.5rem 1rem;
}
