#not-found {
	position: relative;
	font-size: 1.5rem;

	display: flex;
	align-items: center;
	justify-content: center;
	align-self: center;

	height: $main-height;
	width: 100%;
	background-color: $black;

	@media screen and (max-height: $mobile-max) {
		position: static;
		font-size: 1rem;
		text-align: center;
	}
}
