@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-3rem);
	}

	90% {
		opacity: 0.9;
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideUp {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-3);
	}
}
