.detailedComposer {
	@include mainGrayBackground;
	padding: 2rem;

	display: flex;
	align-items: flex-start;
	justify-content: center;
}

.detailedComposerInner {
	max-width: 98%;
	padding: 3rem 2rem;

	display: flex;
	align-items: flex-start;
	flex-direction: column;

	background-color: $darkGray;
	box-shadow: -0.2rem 0 0 0 $white;

	& > * {
		margin: 0.5rem 0;
	}

	@media screen and (min-width: $tablet-width) {
		width: 65%;
	}

	@media screen and (max-width: $mobile-max) {
		padding: 3rem 1rem;
	}

	@media screen and (max-width: 43rem) {
		padding: 3rem 0;
	}
}

.detailedComposerName {
	font-size: 4rem;

	margin: 0;
	margin-left: 0.5rem;

	@media screen and (max-width: $mobile-max) {
		font-size: 3rem;
	}
}

.detailedComposerDates {
	font-size: 2rem;

	margin: 0 0 0.5rem 0.5rem;

	@media screen and (max-width: $mobile-max) {
		font-size: 1.5rem;
	}
}

.detailedComposerMoreInfo {
	color: $white;
	font-size: 1.8rem;

	margin-left: .5rem;

	@media screen and (max-width: $mobile-max) {
		font-size: 1.8rem;
	}
}

.detailedComposerAlbumIntro {
	font-size: 2rem;

	margin-top: 4rem;
	margin-left: .5rem;

	@media screen and (max-width: $mobile-max) {
		font-size: 1.6rem;
		margin-top: 2rem;
	}
}

.detailedComposerAlbumItem {
	display: flex;
	margin: 1rem 0;
	padding: 1.2rem;
	background-color: $offBlack;

	@media screen and (max-width: $mobile-max) {
		width: 98%;
	}
}

.detailedComposerAlbumInfoContainer {
	margin: 1rem;
}

.detailedComposerAlbumLink {
	display: flex;
	text-decoration: none;
	color: $white;

	transition: all 0.2s ease;

	&:hover {
		filter: brightness(0.8);
	}
}

.detailedComposerAlbumImage {
	max-height: 15rem;
	max-width: 15rem;

	transition: all 0.2s ease;

	&:hover {
		filter: brightness(0.8);
	}
}

.detailedComposerAlbumItemTitle {
	font-size: 2rem;

	@media screen and (max-width: $mobile-max) {
		font-size: 1.6rem;
		margin-bottom: 0.3rem;
	}
}

.no-pic-text {
	text-align: center;
}
