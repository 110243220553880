.overflowR {
	overflow: hidden;
}

.pointer {
	cursor: pointer;
}

.hoverOpacity {
	transition: all 180ms;

	&:hover {
		color: gray;
	}
}

.whiteUnderline {
	border-bottom: 0.1rem solid white !important;
}
